import createTheme from "@mui/material/styles/createTheme";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    type: "light",
    primary: {
      main: "#0f4c81",
      light: "#0f4c81",
      dark: "#0f4c81",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#F4F9FF"
    }
  },
  // palette: {
  // primary: { // Generally used by AppBar
  // main: "#000000",
  // light: ;
  // main: ;
  // dark: ;
  // contrastText: ;
  // },
  // secondary: { // This would be the accent color
  // main: "#000000",
  //     light: ;
  //     main: ;
  //     dark: ;
  //     contrastText: ;
  // },
  //   error: {
  //     main: "#BE3455",
  //     light: ;
  //     main: ;
  //     dark: ;
  //     contrastText: ;
  //   },
  //   warning: {
  //     main: "#BE3455",
  //     light: ;
  //     main: ;
  //     dark: ;
  //     contrastText: ;
  //   },
  //   info: {
  //     main: "#BE3455",
  //     light: ;
  //     main: ;
  //     dark: ;
  //     contrastText: ;
  //   },
  //   success: {
  //     main: "#BE3455",
  //     light: ;
  //     main: ;
  //     dark: ;
  //     contrastText: ;
  //   },
  // background: {
  //   default: "#000000",
  //   paper: "#000000"
  // },
  // text: {
  //   primary: "#ffffff",
  //   secondary: "#ffffff",
  //   disabled: "#ffffff"
  // },
  // divider: "#ffffff",
  //   action: {
  //     active: ;
  //     hover: ;
  //     hoverOpacity: ;
  //     selected: ;
  //     selectedOpacity: ;
  //     disabled: ;
  //     disabledOpacity: ;
  //     disabledBackground: ;
  //     focus: ;
  //     focusOpacity: ;
  //     activatedOpacity: ;
  //   }
  // }
});

export default theme;
