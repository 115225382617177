import React from "react";
import { Divider, Typography } from "@mui/material";
import { AcUnit } from "@mui/icons-material";

function DividerTitle(props) {
  return (
    <React.Fragment>
      <Divider sx={{ m: 0, p: 0 }} id={props.nextLink}>
        <AcUnit color="primary" fontSize="large" />
      </Divider>
      <Typography
        display={props.nextTitle === "" ? "none" : "block"}
        sx={{
          typography: {
            xs: "h4",
            sm: "h4",
            md: "h3",
            lg: "h3",
            xl: "h3",
          },
          m: 0,
          p: 0,
        }}
        paragraph
        textAlign={"center"}
      >
        {props.nextTitle}
        <Divider
          sx={{
            width: 50,
            borderWidth: 5,
            borderRadius: 1,
            mx: "auto",
            marginTop: 2,
          }}
        />
      </Typography>
    </React.Fragment>
  );
}

export default DividerTitle;
