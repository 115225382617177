import * as React from "react";
import {
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import constants from "./assets/constants.json";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 300,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 300,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

function Skills() {
  const [open, setOpen] = React.useState(false);
  const [skill, setSkill] = React.useState(false);

  const handleClickOpen = (skill) => {
    setOpen(true);
    setSkill(skill);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Box my={5} sx={{ display: "flex", flexWrap: "wrap", minWidth: 300 }}>
        {constants.skills.map((skill) => (
          <ImageButton
            focusRipple
            key={skill.title}
            style={{ width: "33.3%" }}
            onClick={() => handleClickOpen(skill)}
          >
            <ImageSrc
              style={{
                backgroundImage: `url(${require("" + skill.image + "")})`,
              }}
            />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="h6"
                color="inherit"
                sx={{
                  position: "relative",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {skill.title}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>

      <Dialog open={open} skill={skill} onClose={handleClose}>
        <DialogTitle>{skill.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography paragraph variant="body1">
              <span dangerouslySetInnerHTML={{__html: skill.description}}></span>
            </Typography>
          </DialogContentText>
          <Stack direction="row" flexWrap={"wrap"} gap={1}>
            {skill.items
              ? skill.items.map((item) => <Chip label={item} />)
              : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Skills;
