import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container, CssBaseline } from "@mui/material";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import theme from "./theme.js";
import Hello from "./Hello.js";
import NavBar from "./NavBar.js";
import Introduction from "./Introduction.js";
import Background from "./Background.js";
import Skills from "./Skills.js";
import Contact from "./Contact.js";
import DividerTitle from "./DividerTitle.js";
import SalaryCalculator from "./SalaryCalculator.js";
import Layout from "./Layout.js";

const components = [
  { item: <NavBar />, nextLink: "HomeLink", nextTitle: "" },
  { item: <Hello />, nextLink: "AboutLink", nextTitle: "" },
  {
    item: <Introduction />,
    nextLink: "BackgroundLink",
    nextTitle: "Background",
  },
  { item: <Background />, nextLink: "SkillsLink", nextTitle: "Skills" },
  { item: <Skills />, nextLink: "ContactLink", nextTitle: "Contact" },
  { item: <Contact />, nextLink: "", nextTitle: "" },
];

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Simranjazz />} />
          <Route path="*" element={<Simranjazz />} />
          <Route path="salarycalculator" element={<SalaryCalculator />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

function Simranjazz() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {components.map((component, index) => (
          <React.Fragment>
            <Container maxWidth={false} disableGutters>
              {component.item}
            </Container>

            {/* No need to show DividerTitle at the beginning and the end of the page */}
            {index === 0 || index === components.length - 1 ? (
              ""
            ) : (
              <DividerTitle
                nextTitle={component.nextTitle}
                nextLink={component.nextLink}
              />
            )}
          </React.Fragment>
        ))}
      </ThemeProvider>
    </React.Fragment>
  );
}
