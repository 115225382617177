import React from "react";
import { Hiking, WorkspacePremium, ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Collapse,
  Chip,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  styled,
  Typography,
} from "@mui/material";

const ExpandMoreAction = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function getIcon(icon) {
  var returnIcon;
  switch (icon) {
    case "Promotion":
      returnIcon = <Chip icon={<Hiking />} label={icon} />;
      break;
    case "Certification":
      returnIcon = <Chip icon={<WorkspacePremium />} label={icon} />;
      break;
    default:
      returnIcon = <Chip icon={<Hiking />} label={icon} />;
  }
  return returnIcon;
}

export default function TimelineItemContent(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card elevation={0} sx={{ backgroundColor: "secondary.main" }}>
      <CardActionArea
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <CardHeader
          avatar={
            <Avatar
              aria-label={props.data.title}
              src={props.data.favicon}
              variant="square"
              alt={props.data.title}
            />
          }
          title={
            <Typography textAlign={"left"} variant="h6">
              {props.data.title}
            </Typography>
          }
          subheader={
            <Grid>
              <Box textAlign={"left"}>
                <Typography variant="button">
                  {props.data.subheader.period}
                </Typography>
              </Box>
              <Box textAlign={"left"}>
                <Typography variant="body1">
                  {props.data.subheader.designation}
                </Typography>
              </Box>
            </Grid>
          }
          action={
            <ExpandMoreAction
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMore />
            </ExpandMoreAction>
          }
        />
      </CardActionArea>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List dense={true} disableGutters disablePadding disableSpacing>
            {props.data.content
              ? props.data.content.map((contentItem, index) => (
                  <React.Fragment>
                    {index !== 0 ? (
                      <Divider variant="fullWidth" component="li" />
                    ) : null}
                    {contentItem.type === "listSubHeader" ? (
                      <ListSubheader sx={{textAlign:"left", fontWeight:"bold", lineHeight:{xs: 1.8, sm: 1.8, md: 3}}}>{contentItem.text}</ListSubheader>
                    ) : null}
                    {contentItem.type === "listItem" ? (
                      <ListItem disableGutters disableSpacing disablePadding>
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              {contentItem.text}
                            </Typography>
                          }
                          secondary={
                            <Stack direction="row" spacing={1}>
                              {contentItem.chips
                                ? contentItem.chips.map((chips) =>
                                    getIcon(chips)
                                  )
                                : null}
                            </Stack>
                          }
                        />
                      </ListItem>
                    ) : null}
                  </React.Fragment>
                ))
              : null}
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}
