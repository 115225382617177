import React from "react";
import { Grid, Typography, Card, CardMedia } from "@mui/material";
import ImageGS from "./assets/images/gs.png";
import constants from "./assets/constants.json";
import { useTheme } from '@mui/material/styles';

function Introduction() {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Grid container alignItems={"center"} paddingBottom={3}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          justifyContent={"center"}
          display={"flex"}
        >
          <Typography
            sx={{
              typography: { xs: "h6", sm: "h6", md: "h5" },
              width: "80%",
              textAlign: "center",
            }}
          >
            <p>{constants.introduction}</p>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} textAlign={"center"}>
          <Card sx={{backgroundColor: `${theme.palette.background.default}`}} elevation={0}>
            <CardMedia
              sx={{ height: { xs: 400, sm: 400, md: 800 }, backgroundColor: `${theme.palette.background.default}` }}
              image={ImageGS}
              title={constants.name.full}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Introduction;
