import React from "react";
import { Stack, Typography } from "@mui/material";
import constants from "./assets/constants.json";
import { useTheme } from '@mui/material/styles';

function Hello() {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Stack
        direction={"column"}
        justifyContent={"center"}
        sx={{ height: { xs: "80vh", sm: "80vh", md: "85vh" }, m: 0, p: 0 }}
      >
        <Typography
          align="center"
          sx={{ typography: { xs: "h3", sm: "h3", md: "h1" } }}
        >
          {constants.hello}{" "}
          <span style={{color: theme.palette.primary.main}}>{constants.name.first}</span>.
        </Typography>
        <Typography
          align="center"
          sx={{ typography: { xs: "h5", sm: "h5", md: "h2" } }}
        >
          {constants.helloTitles}
        </Typography>
      </Stack>
    </React.Fragment>
  );
}

export default Hello;
