import React from "react";
import { Email, LinkedIn } from "@mui/icons-material";
import { Stack, IconButton } from "@mui/material";
import constants from "./assets/constants.json";

function Contact() {
  return (
    <React.Fragment>
      <Stack
        direction={"row"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: { xs: "70vh", sm: "70vh", md: "70vh" }, m: 0, p: 0 }}
      >
        <IconButton
          target="_blank"
          href={constants.linkedInUrl}
          rel="noopener"
          color="primary"
        >
          <LinkedIn fontSize="large" sx={{ fontSize: "xxx-large" }} />
        </IconButton>
        <IconButton href={constants.gmail} color="primary">
          <Email sx={{ fontSize: "xxx-large" }} />
        </IconButton>
      </Stack>
    </React.Fragment>
  )
}

export default Contact;
