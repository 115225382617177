import React from "react";
import { Box } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  timelineItemClasses,
} from "@mui/lab";
import { Work, School } from "@mui/icons-material";
import TimelineItemContent from "./TimelineItemContent.js";
import constants from "./assets/constants.json";

function getIcon(icon) {
  var returnIcon;
  switch (icon) {
    case "Work":
      returnIcon = (
        <Work color="action" sx={{ fontSize: { md: "xx-large" } }} />
      );
      break;
    case "School":
      returnIcon = (
        <School color="action" sx={{ fontSize: { md: "xx-large" } }} />
      );
      break;
    default:
      returnIcon = (
        <Work color="action" sx={{ fontSize: { md: "xx-large" } }} />
      );
  }
  return returnIcon;
}

function Background() {
  return (
    <React.Fragment>
      <Box disableGutters>
        {/* Desktop only */}
        <Timeline
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
          position="alternate"
        >
          {constants.timeline.map((timelineItem, index) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot>{getIcon(timelineItem.icon)}</TimelineDot>
                {index !== constants.timeline.length - 1 ? (
                  <TimelineConnector />
                ) : null}
              </TimelineSeparator>
              <TimelineContent>
                <TimelineItemContent data={timelineItem} />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>

        {/* Mobile only */}
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            padding: 0,
            margin: 1.8,
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          {constants.timeline.map((timelineItem, index) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot>{getIcon(timelineItem.icon)}</TimelineDot>
                {index !== constants.timeline.length - 1 ? (
                  <TimelineConnector />
                ) : null}
              </TimelineSeparator>
              <TimelineContent>
                <TimelineItemContent data={timelineItem} />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>
    </React.Fragment>
  );
}

export default Background;
