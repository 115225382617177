import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Person, Work, Psychology, Email } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Link,
  Toolbar,
  Typography,
} from "@mui/material";

const navItems = [
  { name: "About", icon: <Person fontSize="large" /> },
  { name: "Background", icon: <Work fontSize="large" /> },
  { name: "Skills", icon: <Psychology fontSize="large" /> },
  { name: "Contact", icon: <Email fontSize="large" /> },
];
const logo = (
  <Typography
    textAlign={{ xs: "center", sm: "center", md: "left" }}
    variant="h5"
    component="h5"
    sx={{ flexGrow: 1 }}
  >
    <Link
      component={RouterLink}
      to="/"
      color={"inherit"}
      sx={{ textDecoration: "none" }}
    >
      simranjazz.com
    </Link>
  </Typography>
);

function NavBar(props) {
  const [value, setValue] = React.useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <AppBar position="fixed" component="nav" elevation={0}>
          <Toolbar>
            {logo}
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              {navItems.map((item) => (
                <Button
                  key={item.name}
                  color="inherit"
                  size={"large"}
                  href={"#" + item.name + "Link"}
                  sx={{ mx: 1, display: props.hideNavBarLinks === true ? "none": "block" }}
                >
                  {item.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </Box>

      <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
        <AppBar position="relative" component="nav" elevation={0}>
          <Toolbar>{logo}</Toolbar>
        </AppBar>
        <Paper
          sx={{
            bottom: 0,
            position: "fixed",
            top: "auto",
            zIndex: 1000,
            width: "100%",
            display: props.hideNavBarLinks === true ? "none": "block"
          }}
          elevation={0}
        >
          <BottomNavigation
            value={value}
            onChange={handleChange}
            sx={{ backgroundColor: "secondary.main" }}
          >
            {navItems.map((item) => (
              <BottomNavigationAction
                label={item.name}
                value={item.name}
                icon={item.icon}
                href={"#" + item.name + "Link"}
              />
            ))}
          </BottomNavigation>
        </Paper>
      </Box>
    </React.Fragment>
  );
}

export default NavBar;
